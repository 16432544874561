/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

export const buildHref = ({
  path,
  query,
}: {
  path: string
  query?: Record<string, unknown>
}): string => {
  if (!query) {
    return path
  }

  let interpolatedPath = path

  const search = new URLSearchParams()
  Object.entries(query)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    .filter(([, value]) => ![undefined, null, ''].includes(value as any))
    .forEach(([key, value]) => {
      if (interpolatedPath.includes(`:${key}`)) {
        interpolatedPath = interpolatedPath.replace(`:${key}`, String(value))
      } else {
        search.append(key, String(value))
      }
    })
  const searchString = search.toString()
  if (!searchString) {
    return interpolatedPath
  }

  return `${interpolatedPath}?${searchString}`
}
